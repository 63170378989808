html {
  overflow-x: hidden;
}

html, body {
  height: 100%;
}

#__next {
  display: grid;
  min-height: 100%;
}

#fc_frame {
  right: 10px !important;
  bottom: calc(max(15px, var(--figma-panel-size))) !important;
}

body[data-keyframe-inspector-panel-open="true"] #fc_frame {
  transform: translateX(-300px);
  transition: transform 200ms ease-out;
}

/* #region toastify */
:root {
  --toastify-toast-min-height: 32px;
  --toastify-color-progress-light: #18a0fb;
  --reactour-accent: #18a0fb;
}

.Toastify__toast {
  @apply rounded-md;
}

.Toastify__toast-body {
  @apply text-xs;
}
/* #endregion */
